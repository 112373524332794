import type { ErrorType } from '@readme/api/src/core/legacy_mappings/error';
import type { CustomPageReadType } from '@readme/api/src/mappings/custompage/types';
import type { Path } from 'react-hook-form';

import React, { useCallback, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import useEventListener from '@core/hooks/useEventListener';
import { fetcher } from '@core/hooks/useReadmeApi';
import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore } from '@core/store';
import type { HTTPError } from '@core/utils/types/errors';

import { useProjectSettingsFormContext } from '@routes/SuperHub/Settings/Form/Project';
import type { ProjectSettingsFormValues } from '@routes/SuperHub/Settings/Form/Project';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Spinner from '@ui/Spinner';

import classes from './index.module.scss';

interface AddPageInputProps {
  /**
   * Name of the field to register any API errors with.
   * This allows the ProjectSettingsForm to display the error message.
   */
  name: Path<ProjectSettingsFormValues>;
  /**
   * Callback called when the user exits the input.
   */
  onExit: (page?: CustomPageReadType) => void;
}

function AddPageInput({ name, onExit }: AddPageInputProps) {
  const formRef = useRef<HTMLFormElement | null>(null);
  const uid = useUniqueId('AddPageInput');
  const bem = useClassy(classes, 'CustomPagePicker');
  const [value, setValue] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const { setError, clearErrors } = useProjectSettingsFormContext();
  const subdomain = useProjectStore(s => s.data?.subdomain);

  const handleAddPage = useCallback(async () => {
    setIsLoading(true);

    try {
      const response: CustomPageReadType = await fetcher(`/${subdomain}/api-next/v2/custom_pages`, {
        method: 'POST',
        body: JSON.stringify({ title: value }),
      });
      onExit(response);
    } catch (error) {
      const errorInfo = (error as HTTPError).info as ErrorType;
      const message = errorInfo.errors?.[0].message;
      // When the API returns an error, we want to display it in the CustomPagePicker's FormGroup ui.
      // So we register the error with the ProjectSettingsForm state.
      if (message) setError(name, { type: 'manual', message });

      throw new Error('Failed to create page');
    } finally {
      setIsLoading(false);
    }
  }, [name, onExit, setError, subdomain, value]);

  useEventListener(
    'keydown',
    (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        onExit();
      }

      // Prevent submission of parent ProjectSettingsForm on enter
      if (ev.key === 'Enter') {
        ev.preventDefault();
        handleAddPage();
      }
    },
    { target: formRef.current },
  );

  return (
    <form ref={formRef} id={uid('form')}>
      <Input
        className={bem('&', '-input')}
        onChange={e => {
          clearErrors(name);
          setValue(e.target.value);
        }}
        placeholder="Enter a page title"
        required
        size="sm"
        suffix={
          <Flex align="center" gap="xs">
            {isLoading ? (
              <Spinner className={bem('-spinner')} size="sm" />
            ) : (
              <>
                <Button aria-label="Cancel" kind="secondary" onClick={() => onExit()} size="xs">
                  <Icon name="x" />
                </Button>
                <Button aria-label="Add Custom Page" form={uid('form')} onClick={handleAddPage} size="xs" type="button">
                  <Icon name="plus" />
                </Button>
              </>
            )}
          </Flex>
        }
        wrapperClassName={bem('-input-wrapper')}
      />
    </form>
  );
}

export default AddPageInput;
