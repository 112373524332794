import type { MouseEventHandler } from 'react';

import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import { RHFGroup } from '@ui/RHF';

import { useProjectSettingsFormContext } from '..';
import { UnversionedWarning } from '../shared';

import styles from './index.module.scss';

export default function GlossaryTermInput({
  index,
  isCreating,
  onCancel,
  onDelete,
}: {
  index: number;
  isCreating: boolean;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onDelete: MouseEventHandler<HTMLButtonElement>;
}) {
  const uid = useUniqueId('Glossary');
  const bem = useClassy(styles, 'Glossary');
  const {
    control,
    formState: { isSubmitting },
    setFocus,
  } = useProjectSettingsFormContext();
  const term = useWatch({ control, name: `glossary.${index}.term` });

  // Always initialize with focus on the term input field.
  useEffect(() => {
    setFocus(`glossary.${index}.term`);
  }, [index, setFocus]);

  return (
    <>
      <Flex align="stretch" className={bem('-input')} gap={0} justify="end" layout="col">
        <section className={bem('-term')}>
          <span className={bem('-term-name')}>
            <RHFGroup control={control} id={uid('term')} name={`glossary.${index}.term`} required size="sm">
              {({ field: termField }) => (
                <input
                  autoComplete="off"
                  className={bem('-input-field')}
                  placeholder="Term"
                  spellCheck="false"
                  {...termField}
                />
              )}
            </RHFGroup>
          </span>

          <Flex align="stretch" className={bem('-term-definition')} gap="sm" justify="start" layout="col">
            <RHFGroup control={control} id={uid('definition')} name={`glossary.${index}.definition`} required size="sm">
              {({ field: definitionField }) => (
                <input
                  autoComplete="off"
                  className={bem('-input-field')}
                  placeholder="Definition "
                  {...definitionField}
                />
              )}
            </RHFGroup>
            <Flex align="center" className={bem('-term-usage')} gap="xs" justify="start">
              Usage: <code className={bem('-term-preview')}>{`<<glossary:${term}>>`}</code>
            </Flex>
          </Flex>
        </section>

        <Flex align="center" className={bem('-input-footer')} gap="xs" justify={'between'} tag="footer">
          <Flex align="center" gap="sm">
            {!isCreating && (
              <Button kind="destructive" onClick={onDelete} size="xs" text>
                <Icon name="trash" />
              </Button>
            )}

            <UnversionedWarning />
          </Flex>

          <Flex gap="xs">
            <Button disabled={isSubmitting} kind="secondary" onClick={onCancel} size="xs" text>
              Cancel
            </Button>
            <Button loading={isSubmitting} size="xs" type="submit">
              Save Changes
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
