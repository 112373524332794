import React from 'react';

import { Layout } from '@routes/Dash/CustomBlocks/Management';
import { CustomBlockMgmtContextProvider } from '@routes/Dash/CustomBlocks/Management/Context';

import { Page } from '../shared';

function ReusableContent() {
  return (
    <Page renderActionSlot={false}>
      <CustomBlockMgmtContextProvider type="content">
        <Layout />
      </CustomBlockMgmtContextProvider>
    </Page>
  );
}

export default ReusableContent;
