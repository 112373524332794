import React from 'react';
import { HashRouter, Route } from 'react-router-dom';

import useEnvInfo from '@core/hooks/useEnvInfo';
import { useSuperHubStore } from '@core/store';

import { SuperHubHashRoutePaths } from '@routes/SuperHub/types';

import Transition from './Layout/Transition';
import AppearancePanel from './Settings/Appearance';
import ConfigurePanel from './Settings/Configure';

/**
 * Our SuperHub hash router that contains matched segments to determine what
 * action is being performed in what location.
 */
export default function SuperHubHashRouter() {
  const { isClient } = useEnvInfo();
  const layout = useSuperHubStore(s => s.layout);

  return isClient ? (
    <HashRouter>
      {/* Manage project settings such as Configure, Content, etc. */}
      <Transition
        enter={[
          { transform: 'translateY(calc(var(--SuperHub-top-nav-height) + 30px))', opacity: 0 },
          { transform: 'translateY(var(--SuperHub-top-nav-height)', opacity: 1 },
        ]}
        in={layout === 'settings'}
      >
        <Route path={SuperHubHashRoutePaths.settings}>
          <ConfigurePanel />
        </Route>
      </Transition>

      {/* Manage appearance settings such as Theme, Navigation, Custom CSS */}
      <Transition
        enter={[{ transform: 'translateX(var(--SuperHub-aside-width))' }, { transform: 'translateX(0)' }]}
        in={layout === 'settings-preview'}
      >
        <Route path={SuperHubHashRoutePaths.appearance}>
          <AppearancePanel />
        </Route>
      </Transition>
    </HashRouter>
  ) : null;
}
