import React from 'react';
import { createPortal } from 'react-dom';
import { Prompt } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import useOnRouteChange from '@core/hooks/useOnRouteChange';
import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore } from '@core/store';

import { useSuperHubActionSlotContext } from '@routes/SuperHub/Layout/Context';

import Button from '@ui/Button';
import Flex from '@ui/Flex';

import styles from './index.module.scss';
import { UnversionedWarning } from './shared';
import { getDirtyValues } from './utils';

import { useProjectSettingsFormContext } from '.';

interface ProjectSettingsFormContentProps {
  children: React.ReactNode;
}

/**
 * Renders the main `<form>` along with its action controls and submit handlers.
 */
export default function ProjectSettingsFormContent({ children }: ProjectSettingsFormContentProps) {
  const bem = useClassy(styles, 'ProjectSettingsFormContent');
  const uid = useUniqueId('ProjectSettingsFormContent');
  const [isSaving, save] = useProjectStore(s => [s.isSaving, s.save]);
  const actionSlot = useSuperHubActionSlotContext();
  const {
    handleSubmit,
    reset,
    formState: { dirtyFields, isDirty },
  } = useProjectSettingsFormContext();

  // Always "reset" our top-level form when navigating to a different form.
  useOnRouteChange(() => {
    if (!isDirty) return;
    reset();
  });

  const onSubmit = handleSubmit(async data => {
    const dirtyValues = getDirtyValues(dirtyFields, data);
    await save(dirtyValues);
    reset(data);
  });

  return (
    <form className={bem()} id={uid('form')} onSubmit={onSubmit}>
      {!!actionSlot &&
        createPortal(
          <Flex align="center" gap={15} justify="end">
            {!!isDirty && <UnversionedWarning />}

            <Flex align="center" gap="sm">
              <Button disabled={!isDirty} kind="secondary" onClick={() => reset()}>
                Reset
              </Button>
              <Button disabled={!isDirty} form={uid('form')} loading={isSaving} type="submit">
                Save
              </Button>
            </Flex>
          </Flex>,
          actionSlot,
        )}
      {children}
      <Prompt
        message={() => 'Are you sure you want to leave this page? Any unsaved changes will be lost.'}
        when={isDirty}
      />
    </form>
  );
}
