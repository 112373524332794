import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { ProjectContext, type ProjectContextValue } from '@core/context';

import Box from '@ui/Box';
import Button from '@ui/Button';
import FormGroup from '@ui/FormGroup';
import Input from '@ui/Input';
import SmartLink from '@ui/SmartLink';
import { TabsNav } from '@ui/Tabs';

export default function GitConnectionSetup() {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { subdomain } = project;

  // TODO https://linear.app/readme-io/issue/RM-10188/gitlab-install-endpoint
  // const gitlabParams = new URLSearchParams({
  //   client_id: 'client_id',
  //   redirect_uri: 'http://readme.local:3000/api-next/v2/git_sync/gitlab/callback',
  //   response_type: 'code',
  //   scope: 'write_repository read_api',
  //   state: project._id,
  // });

  const tabs = [
    { label: 'GitHub', to: '/github' },
    // { label: 'GitLab', to: '/gitlab' },
  ];

  let { path } = useRouteMatch();
  path = path.replace(/\/$/, '');

  return (
    <Box theme="dark">
      <TabsNav tabs={tabs} />
      <Switch>
        <Route path={`${path}/github`}>
          <SmartLink href="https://github.com/new" rel="noreferrer" target="_blank">
            Create a new repository
          </SmartLink>
          <br />
          <Button href={`/${subdomain}/api-next/v2/git_sync/github/init`} kind="secondary" size="lg">
            Sync with GitHub
          </Button>
        </Route>
        <Route path={`${path}/gitlab`}>
          <SmartLink href="https://gitlab.com/projects/new" rel="noreferrer" target="_blank">
            Create a new repository
          </SmartLink>
          <br />
          <FormGroup htmlFor="clientId" label="Client ID" size="sm">
            <Input id="clientId" size="sm" />
          </FormGroup>
          <FormGroup htmlFor="clientSecret" label="Client Secret" size="sm">
            <Input id="clientSecret" size="sm" />
          </FormGroup>
          {/* <Button */}
          {/*  href={`https://gitlab.com/oauth/authorize?${gitlabParams.toString()}`} */}
          {/*  kind="secondary" */}
          {/*  rel="noreferrer" */}
          {/*  size="lg" */}
          {/* > */}
          {/*  Sync with Gitlab */}
          {/* </Button> */}
        </Route>
        <Route exact path={path}>
          {/* Redirect to the first tab if we're at the root path. */}
          {!!tabs[0] && <Redirect to={`${path}${tabs[0].to}`} />}
        </Route>
      </Switch>
    </Box>
  );
}
