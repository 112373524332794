import React, { useEffect, useMemo, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';

import type { DropdownRef } from '@ui/Dropdown';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';

import { useSegmentContext } from '../Context';
import classes from '../index.module.scss';

export interface SegmentItemProps {
  /**
   * The index of the item in the segment group. This is applied automatically by the `Segment` component.
   */
  _index?: number;
  /**
   * Content of the item.
   */
  children: React.ReactNode;
  /**
   * The className to apply to the item.
   */
  className?: string;
  /**
   * A Dropdown menu to display when the item is clicked.
   */
  dropdownMenu?: React.ReactNode;
  /**
   * Callback fired when the item is clicked.
   */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * A route to link to when the item is clicked.
   */
  to?: string;
  /**
   * Value of the item.
   */
  value?: string;
}

/**
 * A single item in a `Segment` component.
 */
export default function SegmentItem({ _index, children, className, onClick, to, dropdownMenu }: SegmentItemProps) {
  const ref = useRef<DropdownRef | HTMLAnchorElement | HTMLButtonElement>(null);
  const bem = useClassy(classes, 'SegmentItem');
  const { selectedIndex, setSelectedIndex, setHighlightPosition } = useSegmentContext();

  useEffect(() => {
    // register resize observer on ref.current
    const element = ref.current && 'triggerElement' in ref.current ? ref.current?.triggerElement : ref.current;
    if (element) {
      const observer = new ResizeObserver(() => {
        if (selectedIndex === _index) {
          setHighlightPosition({ left: element.offsetLeft, width: element.offsetWidth });
        }
      });
      observer.observe(element);
      return () => {
        observer.disconnect();
      };
    }

    return undefined;
  }, [_index, selectedIndex, setHighlightPosition]);

  const commonProps = useMemo(
    () => ({
      className: bem('&', selectedIndex === _index && '_active', className),
      role: 'tab',
      'aria-selected': selectedIndex === _index,
    }),
    [_index, bem, selectedIndex, className],
  );

  return to ? (
    // @ts-ignore the inferred type of ref is unweildy
    <NavLink ref={ref} {...commonProps} to={to}>
      {children}
    </NavLink>
  ) : dropdownMenu ? (
    // @ts-ignore the inferred type of ref is unweildy
    <Dropdown ref={ref} appendTo={document.body} clickInToClose justify="start">
      <button {...commonProps}>
        {children}
        <Icon className={bem('-chevron')} name="chevron-down" />
      </button>
      {dropdownMenu}
    </Dropdown>
  ) : (
    <button
      // @ts-ignore the inferred type of ref is unweildy
      ref={ref}
      {...commonProps}
      onClick={event => {
        onClick?.(event);
        setSelectedIndex(_index);
      }}
    >
      {children}
    </button>
  );
}
