import type { RepositoriesListType } from '@readme/api/src/routes/gitSync/types';

import React, { useCallback, useContext, useEffect, useState } from 'react';

import { ProjectContext, type ProjectContextValue } from '@core/context';
import { useReadmeApiNext } from '@core/hooks/useReadmeApi';
import capitalize from '@core/utils/capitalize';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import InputGroup from '@ui/InputGroup';
import Radio from '@ui/Radio';
import RadioGroup from '@ui/RadioGroup';
import Select from '@ui/Select';
import SmartLink from '@ui/SmartLink';
import Spinner from '@ui/Spinner';

import { Fieldset, FormRow } from '../shared';

export default function GitConnectionChooseRepo() {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { subdomain } = project;
  const provider = project.git.sync.connectedRepository?.provider || 'github';

  const { data: { data = [] } = {}, isLoading } = useReadmeApiNext<{ data: RepositoriesListType[] }>(
    `/git_sync/repositories?provider=${provider}`,
    {
      swr: {
        revalidateOnFocus: true,
        shouldRetryOnError: true,
      },
    },
  );

  const organizations = data.map(({ owner }) => {
    return {
      label: owner.login,
      value: owner.login,
    };
  });

  const [selectedRepo, setSelectedRepo] = useState<string | null>(project.git.sync.connectedRepository?.id || null);
  const [selectedOrg, setSelectedOrg] = useState(data.length ? data[0].owner : undefined);
  const [repositories, setRepositories] = useState(data.length ? data[0]?.repositories : []);

  const handleSelection = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    setSelectedRepo(e.currentTarget.id);
  }, []);

  useEffect(() => {
    const orgIdx = data.length && selectedOrg ? data.map(d => d.owner.login).indexOf(selectedOrg.login) : 0;
    const repos = data.length && data[orgIdx] ? data[orgIdx].repositories : [];
    setRepositories(repos);
  }, [selectedOrg, data]);

  return (
    <Fieldset legend={`${capitalize(provider)} Connection`}>
      <SmartLink
        href={
          selectedOrg
            ? `https://github.com/settings/installations/${selectedOrg.connectionID}`
            : 'https://github.com/settings/installations'
        }
        rel="noreferrer"
        target="_blank"
      >
        Manage Connection
      </SmartLink>
      <FormRow columns={2}>
        <FormGroup label="Choose Organization">
          <Flex>
            <InputGroup size="sm">
              {isLoading ? (
                <Spinner size="sm" />
              ) : (
                <Select
                  defaultValue={selectedOrg?.login}
                  onChange={e => {
                    const orgIdx = data.length ? data.map(d => d.owner.login).indexOf(e.target.value) : 0;
                    const org = data[orgIdx].owner || undefined;
                    setSelectedOrg(org);
                  }}
                  options={organizations}
                  size="sm"
                />
              )}
            </InputGroup>
            <SmartLink href={`/${subdomain}/api-next/v2/git_sync/github/init`}>Add new organization</SmartLink>
          </Flex>
        </FormGroup>
        <FormGroup label="Choose Repository">
          <Flex>
            {isLoading ? (
              <Spinner size="sm" />
            ) : (
              <RadioGroup fullWidth>
                {repositories.map(repo => (
                  <Radio key={repo.id} label={repo.name} name="repository" onClick={handleSelection}></Radio>
                ))}
              </RadioGroup>
            )}
            <SmartLink href={'https://github.com/new'} rel="noreferrer">
              Add Repository
            </SmartLink>
          </Flex>
          <br />
          <br />
          <InputGroup size="sm">
            <Button
              onClick={() => {
                project.mutate({
                  git: {
                    sync: {
                      connection: {
                        repository: selectedRepo,
                      },
                    },
                  },
                });
              }}
              size="sm"
            >
              Sync Repository
            </Button>
          </InputGroup>
        </FormGroup>
      </FormRow>
    </Fieldset>
  );
}
