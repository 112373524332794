import type {
  Document,
  GenericMapping,
  MapperReturnType,
  NarrowMapping,
  RecursiveObject,
  validAPIValues,
} from './mapperTypes';
import type { PaginationCollection } from './utils/pagination';
import type { ExpandRecursively } from '@readme/iso';
import type { ZodType } from 'zod';

import { Error as MongooseError } from 'mongoose';
import { z } from 'zod';

import { getNullNotEmptyString } from './cartographer/mapper/util';
import MapperError from './errors/mapperError';
import './fastifyTypes'; // This is being loaded as a typing sideeffect for our request context overrides. DO NOT REMOVE
import {
  isAPreSetType,
  isAPreResolverType,
  isAMappingType,
  isAModelRefType,
  isAnAutoMapType,
  isARejectType,
  isAResolverType,
  isASetType,
  isAShorthandType,
} from './mapperTypes';

/**
 * This is the Zod definition for our single item wrapper. All single-resource representations
 * should be wrapped in this.
 */
export function zodSingleWrapper<T>(t: ZodType<T>) {
  return z.object({
    data: t,
  });
}

/**
 * The Zod definition for our item collection wrapper. All multi-resource representations should
 * be wrapped in this.
 *
 */
export function zodCursorCollectionWrapper<T>(t: ZodType<T>, other?: Record<string, ZodType<number | string>>) {
  return z.object({
    from: z.string(),
    per_page: z.number(),
    paging: z.object({
      next: z.string().nullable(),
      previous: z.string().nullable(),
    }),
    data: z.array(t),
    ...other,
  });
}

/**
 * The Zod definition for our paginated item collection wrapper. All multi-resource representations
 * that support page-based pagination should be wrapped in this.
 *
 */
export function zodCollectionWrapper<T>(t: ZodType<T>, other?: Record<string, ZodType<number | string>>) {
  return z.object({
    total: z.number(),
    page: z.number(),
    per_page: z.number(),
    paging: z.object({
      next: z.string().nullable(),
      previous: z.string().nullable(),
      first: z.string().nullable(),
      last: z.string().nullable(),
    }),
    data: z.array(t),
    ...other,
  });
}

/**
 * The Zod definition for our **unpaginated** item collection wrapper. All multi-resource
 * representations that **do not** support pagination but return a full collection of results should
 * be wrapped in this.
 *
 */
export function zodUnpaginatedCollectionWrapper<T>(t: ZodType<T>, other?: Record<string, ZodType<number | string>>) {
  return z.object({
    total: z.number(),
    data: z.array(t),
    ...other,
  });
}

const genericCollectionWrapper = zodCollectionWrapper(z.unknown());
export type CollectionWrapperType<T> = Omit<z.infer<typeof genericCollectionWrapper>, 'data'> & { data: T[] };

/**
 * This utility method will allow you to convert a Typescript interface property into a common
 * string for use as a mapper `_modelRef`. Model refs are a string that represents a model property
 * in dot-notation. It's used to help interpret errors from Mongoose into their API representation
 * in order to make error messages correctly reference a potentially remapped property on the user's
 * payload.
 *
 * Usage of this method is unnecessary if the property path is unchanged by the mapper (eg. you're
 * mapping `title` to `title`).
 *
 * This function has been slightly modified from the linked StackOverflow question to allow us to
 * support deeply nested properties and also to have simpler function pattern matching.
 *
 * @example
 * getModelRef<ProjectSchema>(m => m.appearance.colors.body_highlight)
 *  -> `appearance.colors.body_highlight`
 *
 * @see {@link https://stackoverflow.com/a/29696050/105698}
 */
export function getModelRef<T>(property: (object: T) => void) {
  let func = property.toString();

  // The function argument to this utility can be written number of different ways so in order to
  // simplify the regex for grabbing our model property to a simpler `(p)=>p.property.name` we need
  // to normalize our data a bit.
  func = func.replace(/(\n|\{|\}|\s|return|;)/g, '');

  const arr = func.match(/(\()?\w+(\))?=>(\w+).(?<ref>.*)/);
  if (!arr || !arr.groups) {
    throw new Error(
      'An improperly formatted function was supplied to `getModelRef`. Functions supplied here should only be written as arrow functions.',
    );
  }

  return arr.groups.ref;
}

/**
 * This uses a mapping structure to translate a model into JSON.
 *
 *  - The model contains the data we persist. The contents of the model aren't relevant to this
 *    function, we just pass it on blindly to the mapping.
 *  - The mapping is a translation layer between the data and the resulting JSON.
 *
 * Each example below uses the following model:
 *
 * {
 *   "a": "letty",
 *   "b": {
 *     "c": "tej",
 *     "d": "o'connor"
 *   },
 *   "e": "shaw"
 * }
 *
 * 1. If the value is anything but `true`, a function, or an object we will not include the field.
 *
 * MAPPING
 * {
 *   "a": false,
 *   "b": null
 * }
 *
 * RESULT
 * { }
 *
 * 2. If the value is `true` it means we should return the value as is.
 *
 * MAPPING
 * { "a": true }
 *
 * RESULT
 * { "a": "letty" }
 *
 * 3. If the value is a function, we pass the model to that function and record the result
 *
 * MAPPING
 * { "a": (model) => model.e }
 *
 * RESULT
 * { "a": "shaw" }
 *
 * 4. If the value is an object, we first look to see if the object has a `_resolve` function. If
 *  so, we treat this like the function in step 3. The reason we might want to use this pattern
 *  instead of step 3 is if that same field can be written to. If so you will also have a `_set`
 *  function
 *
 * NOTE: We do not change anything about the model passed into sub-functions. It's still the top
 * level object.
 *
 * MAPPING
 * {
 *   "a": {
 *     _resolve: (model) => model.e
 *   }
 * }
 *
 * RESULT
 * { "a": "shaw" }
 *
 * 5. If the value is an object but there is no `_resolve` function we recursively navigate down
 *  into that object and then perform steps 1 through 5.
 *
 * NOTE: We do not change anything about the model passed into sub-functions. It's still the top level object.
 * NOTE: We do not recursively navigate into a function if the `_resolve` method is present
 *
 * MAPPING
 * {
 *   "b": {
 *     "c": (model) => model.b.c }
 *   }
 * }
 *
 * RESULT
 * { "a": "shaw" }
 */
export class Mapper<Model extends Document, ThisMapping extends NarrowMapping<unknown>> {
  /**
   * The actual mapping structure used to translate the model into a representation
   */
  mapping: ThisMapping;

  /**
   * When you create the Mapper, we store the values you provide and automatically create JSON
   * Schemas for each representation.
   */
  constructor(mapping: ThisMapping) {
    this.mapping = mapping;
  }

  /**
   * This function handles the actual mapping rendering. We split it from the other rendering
   * functions to isolate the recursion needed.
   *
   * You'll notice inside we do a lot of stuff with promises. The goal there is to get as much of
   * the asynchronous code into promises as fast as possible, and `Promise.all` them in parallel.
   */
  async processRender(model: Model, mapping: GenericMapping<Model>, subModel?: Document) {
    const representation: RecursiveObject = {};
    const currentSubModel = subModel || model;

    await Promise.all(
      Object.keys(mapping).map(key => {
        // True means the field from the model comes from the same place as the mapping
        const subMap = mapping[key];
        const currentModelValue = currentSubModel?.[key];

        // Reject type means exclude this field entirely
        if (isARejectType(subMap)) {
          return Promise.resolve();
        }

        // If the mapping is an auto map type (key => true), then use the same representation key
        // structure on the model
        if (isAnAutoMapType(subMap)) {
          if (currentModelValue !== undefined) {
            const val = currentModelValue;

            // If the value we pull out of the model is a function or object, we hard fail. We only
            // want to return primitives and null.
            if (['function', 'object'].includes(typeof val) && val !== null) {
              throw new TypeError('Invalid mapping');
            }

            // Directly map the model value to the representation
            representation[key] = currentModelValue;
          }

          // Exit out, because we don't want to check other types
          return Promise.resolve();
        }

        // If the mapping is shorthand type (`{ key: resolverFn }`), execute that function and apply
        // it to the representation.
        if (isAShorthandType(subMap)) {
          return Promise.resolve(subMap(model)).then(val => {
            representation[key] = val;
          });
        }

        // If the mapping is a non-null object
        if (typeof subMap === 'object' && subMap !== null) {
          // If the mapping is a resolver type `(key => { _resolve: function })`, execute the
          // resolver function and apply it to the representation.
          if (isAResolverType(subMap)) {
            // If there is a `_preResolve` handler set up for this resolver then we need to evaluate
            // each handler and ensure that they all pass otherwise this data should resolve as
            // `null`.
            if (isAPreResolverType(subMap)) {
              if (!subMap._preResolve.every(fn => fn(model))) {
                return Promise.resolve().then(() => {
                  representation[key] = null;
                });
              }
            }

            return Promise.resolve(subMap._resolve(model)).then(val => {
              // If the value we're receiving back from the resolver is a `CoreMongooseArray` we
              // should translate it to a *real* array so we aren't dealing with Mongoose objects
              // downstream and depending on them being properly translated into JSON for our API
              // responses.
              if (Array.isArray(val)) {
                if (val?.constructor?.name === 'CoreMongooseArray') {
                  representation[key] = [...val] as validAPIValues;
                  return;
                }
              }

              representation[key] = val;
            });
          }

          // If we're dealing with a nested mapper, recurse into it. `currentModelValue` will be
          // undefined if we stop being able to nest
          if (isAMappingType(subMap)) {
            return Promise.resolve(this.processRender(model, subMap, currentModelValue)).then(val => {
              representation[key] = val;
            });
          }
        }

        return Promise.resolve();
      }),
    );

    return representation;
  }

  /**
   * Renders the model via our Mapping system, and wraps it in our standard single wrapper
   */
  async renderSingle<M extends Model>(model: M) {
    return {
      data: (await this.processRender(model, this.mapping)) as ExpandRecursively<MapperReturnType<ThisMapping, M>>,
    };
  }

  /**
   * DO NOT USE THIS UNLESS YOU KNOW WHAT YOU'RE DOING
   *
   * We likely only need this for errors!!!!
   */
  async renderTopLevel<M extends Model>(model: M) {
    return (await this.processRender(model, this.mapping)) as ExpandRecursively<MapperReturnType<ThisMapping, M>>;
  }

  /**
   * Renders the a collection of paginated models via our Mapping system, and wraps it in our
   * standard collection wrapper.
   *
   * @see ./utils/pagination.ts
   */
  async renderCollection<M extends Model>(pagination: PaginationCollection) {
    return {
      total: pagination.total,
      page: pagination.page,
      per_page: pagination.perPage,
      paging: {
        next: getNullNotEmptyString(pagination.paging.next),
        previous: getNullNotEmptyString(pagination.paging.previous),
        first: getNullNotEmptyString(pagination.paging.first),
        last: getNullNotEmptyString(pagination.paging.last),
      },
      data: (await Promise.all(
        pagination.items.map(model => this.processRender(model, this.mapping)),
      )) as ExpandRecursively<MapperReturnType<ThisMapping, M>>[],
      ...pagination.other,
    };
  }

  /**
   * The purpose of this function is to take the incoming JSON request body and apply it to a
   * Mongoose model.
   *
   *  - The model contains the data we persist. The contents of the model aren't relevant to this
   *    function, we just pass it on blindly to the mapping. WE DO NOT SAVE THE MODEL FOR YOU.
   *  - The body is the JSON request body which follows the JSON Merge Patch format as described
   *    below.
   *  - The mapping is a translation layer between the incoming JSON and the model. We traverse the
   *    mapping object (while tracking matching progress in the request body) until we find a
   *    `_set` key. When we do, we call it with the model and the matched value in the request body.
   *
   * We follow the JSON Merge Patch format for applying edits. JSON Merge Patch basically has three
   * rules. The examples below each assume the following base model.
   *
   * {
   *   "a": "letty",
   *   "b": {
   *     "c": "roman",
   *     "d": "o'conner"
   *   }
   * }
   *
   * 1. If a field is provided with a non-null, non-object body, update the data.
   *
   * MAPPING
   * {
   *   "a": {
   *     _set(model, value) => model.a = value;
   *   }
   * }
   *
   * PATCH
   * {
   *   "a": "tej",
   * }
   *
   * RESULT
   * {
   *   "a": "tej",
   *   "b": {
   *        "c": "roman",
   *        "d": "o'conner"
   *   }
   * }
   *
   * 2. If a field is provided with a null value, unset that value. In this example the response
   * tells you the value is unset by returning null, but in some representations the key might not
   * exist or we might show the default value.
   *
   * MAPPING
   * {
   *   "a": {
   *     // This assumes that setting the value to null and saving it will persist the null value.
   *     // You might have to make other modifications for other database field types.
   *     _set(model, value) => model.a = value;
   *   }
   * }
   *
   * PATCH
   * {
   *   "a": null,
   * }
   *
   * RESULT
   * {
   *   "a": null,
   *   "b": {
   *     "c": "roman",
   *     "d": "o'conner"
   *   }
   * }
   *
   * 3. If a field is provided with an object, merge the objects following rule 1 and 2 (do not
   * replace).
   *
   * MAPPING
   * {
   *   "b": {
   *     "c": {
   *       _set(model, value) => model.b.c = value;
   *     }
   *   }
   * }
   *
   * PATCH
   * {
   *   "b": {
   *     "c": "tej",
   *   }
   * }
   *
   * RESULT
   * {
   *   "a": "letty",
   *   "b": {
   *     "c": "tej",
   *     // Notice that while we didn't include `b.d` in the PATCH, it remains in the RESULT. Again
   *     // we merge objects not replace.
   *     "d": "o'conner"
   *   }
   * }
   *
   * @see {@link https://www.rfc-editor.org/rfc/rfc7386}
   */
  update<B extends RecursiveObject>(model: Model, body: B) {
    return this.processUpdate(model, body, this.mapping, body);
  }

  /**
   * Delete a given model.
   *
   */
  // eslint-disable-next-line class-methods-use-this
  async remove(model: Model) {
    await model.remove();
  }

  /**
   * Save any changes that were made against a given model while also translating validation errors
   * that may be thrown within our models into errors that our API can understand and deliver.
   *
   */
  async save(model: Model) {
    if (!model.isModified()) {
      return;
    }

    await model.save(model).catch((err: any) => {
      throw this.convertMongooseErrorToMapperError(model, err);
    });
  }

  /**
   * Handles the actual update process as defined in the `update` method. This is split out of that
   * method to handle nested recursion.
   *
   * You'll notice inside we do a lot of stuff with promises. The goal there is to get as much of
   * the asynchronous code into promises as fast as possible, and into a big array of promises that
   * we can wait on in parallel.
   */
  private async processUpdate<B extends RecursiveObject, RB extends RecursiveObject>(
    model: Model,
    body: B,
    mapping: GenericMapping<Model>,
    rootBody: RB,
    parentKey?: string,
  ) {
    await Promise.all(
      Object.keys(body).map(key => {
        // Protect against prototype pollution.
        if (key === '__proto__') return Promise.resolve();
        const currentKey = parentKey ? `${parentKey}.${key}` : key;
        const subMap = mapping[key];
        const val = body[key];

        // Reject types are ignored entirely
        if (isARejectType(subMap)) {
          return Promise.resolve();
        }

        // If the mapping allows us to set values
        if (isASetType(subMap)) {
          // If there is a `_preSet` handler set up for this setter then we need to evaluate each
          // handler and ensure that they all pass otherwise this data **not** be able to be set.
          if (isAPreSetType(subMap)) {
            if (!subMap._preSet.every(fn => fn(model))) {
              throw new MapperError(`Unable to save ${this.getModelName(model)}.`, [
                {
                  key: currentKey,
                  message: 'A required precondition for setting this data was not met by your request.',
                },
              ]);
            }
          }

          return Promise.resolve(subMap?._set(model, val, rootBody));
        }

        // If the mapping and body are both objects and allow us to recurse deeper, do so
        if (typeof val === 'object' && !Array.isArray(val) && val !== null && isAMappingType(subMap)) {
          return Promise.resolve(this.processUpdate(model, val, subMap, rootBody, currentKey));
        }

        return Promise.resolve();
      }),
    );
  }

  /**
   * Given a dot-notation model key return back the cooresponding entry within a given mapping.
   *
   * This used to map Mongoose errors to API representations.
   */
  getKeyForMappingEntry(lookup: string, mapping: GenericMapping<Model>, parentKey?: string) {
    const foundKey: (string | false)[] = Object.entries(mapping)
      .map(([key, subMap]) => {
        const mapKey = parentKey ? `${parentKey}.${key}` : key;

        if (isARejectType(subMap)) {
          return false;
        } else if (isAnAutoMapType(subMap) || isAShorthandType(subMap)) {
          return lookup === mapKey ? mapKey : false;
        }

        if (typeof subMap === 'object' && subMap !== null) {
          if (isAModelRefType(subMap)) {
            return lookup === String(subMap._modelRef) ? mapKey : false;
          } else if (isAMappingType(subMap)) {
            return this.getKeyForMappingEntry(lookup, subMap, mapKey);
          }

          return lookup === mapKey ? mapKey : false;
        }

        return false;
      })
      .filter(Boolean);

    // This `as` fixes the typing issue where typescript misses we're checking length before shifting off a value next
    return foundKey.length ? (foundKey.shift() as string | false) : false;
  }

  /**
   * Helper function for transforming an instance of the Mongoose `ValidationError` class into
   * our `MapperError` that we can then throw back to the user so they have better formatted API
   * errors.
   *
   */
  convertMongooseErrorToMapperError(model: Model, err: Error) {
    // If this isn't actually a Mongoose ValidationError then we don't really have any other option
    // here than returning what we've got.
    if (!(err instanceof MongooseError.ValidationError)) {
      return err;
    }

    const errors: MapperError['errors'] = [];
    Object.entries(err.errors).forEach(([path, error]) => {
      const errorKey = this.getKeyForMappingEntry(path, this.mapping);

      errors.push({
        key: errorKey !== false ? errorKey : 'unknown',
        message: error.message,
      });
    });

    return new MapperError(`Unable to save ${this.getModelName(model)}.`, errors);
  }

  // eslint-disable-next-line class-methods-use-this
  getModelName(model: Model) {
    // `constructor.modelName` is available in Mongoose instances but just incase it's not here
    // for whatever reason we should have something to fallback to.
    return (model?.constructor as any)?.modelName ?? 'model';
  }
}
