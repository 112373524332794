import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import CodeInput from '@ui/CodeInput';
import { RHFGroup } from '@ui/RHF';

import { useProjectSettingsFormContext } from '..';
import { Page, PageContent } from '../shared';

import BestPractices from './BestPractices';
import classes from './style.module.scss';

function CustomJS() {
  const uid = useUniqueId('CustomJS');
  const bem = useClassy(classes, 'CustomCode');
  const { control } = useProjectSettingsFormContext();

  return (
    <Page>
      <PageContent isCondensed>
        <BestPractices type="javascript" />
        <RHFGroup control={control} id={uid('js')} name="appearance.custom_code.js" size="sm">
          {({ field }) => (
            <CodeInput
              {...field}
              className={bem('-code-input')}
              initialValue={field.value}
              isDarkMode
              language="javascript"
            />
          )}
        </RHFGroup>
      </PageContent>
    </Page>
  );
}

export default CustomJS;
