import React from 'react';
import { useFormContext, FormProvider, useForm } from 'react-hook-form';

import type { ProjectStateUpdate } from '@core/store';
import { useProjectStore } from '@core/store';

import ConnectFormToProjectStore from './ConnectFormToProjectStore';
import ProjectSettingsFormContent from './Content';

export type ProjectSettingsFormValues = ProjectStateUpdate & {
  // TODO: Remove once mapper defines this missing property
  custom_login: {
    PENDING_jwt_expiration: number;
  };
};

interface ProjectSettingsFormProps {
  children: React.ReactNode;
}

/**
 * Sets up the React Hook Form instance that manages our project settings
 * forms. Renders it with a `FormProvider` that allows all child components to
 * access the form context and all of its properties.
 *
 * Child components should call `useProjectSettingsFormContext()` hook to access the
 * form context that is properly typed.
 *
 * @link https://react-hook-form.com/docs/formprovider
 */
export default function ProjectSettingsForm({ children }: ProjectSettingsFormProps) {
  const initialFormValues = useProjectStore(s => s.initialData);
  const formValue = useForm<ProjectSettingsFormValues>({
    defaultValues: initialFormValues,
  });

  return (
    <FormProvider {...formValue}>
      <ConnectFormToProjectStore>
        <ProjectSettingsFormContent>{children}</ProjectSettingsFormContent>
      </ConnectFormToProjectStore>
    </FormProvider>
  );
}

/**
 * Convenience hook to get our React Hook Form Context with types that match our
 * project mapper form fields.
 * @link https://react-hook-form.com/docs/useformcontext
 */
export const useProjectSettingsFormContext = () => useFormContext<ProjectSettingsFormValues>();
