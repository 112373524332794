import React from 'react';

import Icon from '@ui/Icon';
import Menu, { MenuDivider, MenuHeader, MenuItem } from '@ui/Menu';

const appearanceRoutes = [
  {
    icon: 'brush',
    label: 'Theme',
    routeSection: 'theme-editor',
  },
  {
    icon: 'compass',
    label: 'Navigation',
    routeSection: 'navigation',
  },
  {
    icon: 'layout-footer',
    label: 'Footer',
    routeSection: 'footer',
  },
] as const;

const layoutRoutes = [
  {
    icon: 'megaphone',
    label: 'Changelog',
    routeSection: 'changelog',
  },
  {
    icon: 'code',
    label: 'API Reference',
    routeSection: 'api-reference',
  },
] as const;

const customCodeRoutes = [
  {
    icon: 'file-css',
    label: 'CSS',
    routeSection: 'custom-css',
  },
  {
    icon: 'file-js',
    label: 'Javascript',
    routeSection: 'custom-js',
  },
  {
    icon: 'file-html',
    label: 'HTML',
    routeSection: 'custom-html',
  },
] as const;

export const appearanceNavigationItems = [...appearanceRoutes, ...layoutRoutes, ...customCodeRoutes] as const;

const menuSections = {
  '': appearanceRoutes,
  Layout: layoutRoutes,
  'Custom Code': customCodeRoutes,
};

interface AppearanceNavigationMenuProps {
  activeRouteSection?: (typeof appearanceNavigationItems)[number]['routeSection'];
  className?: string;
}

/**
 * Navigation menu for the appearance settings section.
 */
export default function AppearanceNavigationMenu({ activeRouteSection, className }: AppearanceNavigationMenuProps) {
  return (
    <Menu className={className} data-color-mode="dark" theme="dark">
      {Object.entries(menuSections).map(([section, items], index) => (
        <React.Fragment key={section}>
          {!!section && <MenuHeader>{section}</MenuHeader>}
          {items.map(({ icon, label, routeSection }) => (
            <MenuItem
              key={routeSection}
              active={activeRouteSection === routeSection}
              onClick={() => {
                window.location.hash = `/appearance/${routeSection}`;
              }}
            >
              <Icon name={icon} />
              {label}
            </MenuItem>
          ))}
          {index < Object.keys(menuSections).length - 1 && <MenuDivider />}
        </React.Fragment>
      ))}
    </Menu>
  );
}
