import React from 'react';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';

import useUniqueId from '@core/hooks/useUniqueId';

import CodeInput from '@ui/CodeInput';
import { RHFGroup } from '@ui/RHF';
import Toggle from '@ui/Toggle';

import { useProjectSettingsFormContext } from '..';
import { Page, PageContent } from '../shared';

export default function Footer() {
  const uid = useUniqueId('Footer');
  const { control } = useProjectSettingsFormContext();

  const readmeLogo = useWatch({ control, name: 'appearance.footer.readme_logo' });

  return (
    <Page>
      <PageContent isCondensed>
        <RHFGroup
          control={control}
          id={uid('branding')}
          label="Branding"
          name="appearance.footer.readme_logo"
          size="sm"
        >
          {({ field }) => (
            <Toggle
              {...field}
              checked={field.value === 'hide'}
              isLabelMuted
              label={
                <span>
                  <strong>Hide</strong> ReadMe logo in site footer
                </span>
              }
              onChange={event => {
                field.onChange(event.target.checked ? 'hide' : 'show');
              }}
              size="sm"
            />
          )}
        </RHFGroup>

        {readmeLogo === 'hide' && (
          <RHFGroup
            control={control}
            description={
              <>
                <p>
                  Will appear in a <code>footer</code> element on the Homepage only. Use{' '}
                  <code>{'<div class=”container”>'}</code> if you don&apos;t want a full-width footer.
                </p>
                <p>
                  If you want to edit CSS or JS use the{' '}
                  <Link to="/configure/appearance/custom-css-js/css">Custom CSS/JS</Link> settings.
                </p>
              </>
            }
            id={uid('footer-html')}
            label="Footer HTML"
            name="appearance.custom_code.html.home_footer"
            size="sm"
          >
            {({ field }) => <CodeInput {...field} initialValue={field.value} isDarkMode language="html" />}
          </RHFGroup>
        )}
      </PageContent>
    </Page>
  );
}
