import type { CustomBlockMgmtAPIDataInfinite } from './types';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import type { ProjectContextValue, VersionContextValue } from '@core/context';
import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useIsTrialActive from '@core/hooks/useIsTrialActive';
import usePlanPermissions from '@core/hooks/usePlanPermissions';
import { useReadmeApiInfinite } from '@core/hooks/useReadmeApi';

import Box from '@ui/Box';
import ContentContainer from '@ui/Dash/ContentContainer';
import DashHeader from '@ui/Dash/Header';
import ErrorState from '@ui/ErrorState';
import Spinner from '@ui/Spinner';

import Content from './Content';
import { CustomBlockMgmtContextProvider, useCustomBlockMgmtContext } from './Context';
import Cover from './Cover';
import classes from './style.module.scss';

export const PER_PAGE = 20;

export function Layout() {
  const { path } = useRouteMatch();
  const bem = useClassy(classes, 'CustomBlockManagement');
  const { type, filter } = useCustomBlockMgmtContext();

  const { version_clean: version } = useContext(VersionContext) as VersionContextValue;
  const {
    project: { subdomain, name, childrenProjects },
  } = useContext(ProjectContext) as ProjectContextValue;

  const apiV2Path = `/${subdomain}/api-next/v2`;
  const isGroup = !!childrenProjects?.length;
  const apiPath = `${apiV2Path}/versions/${isGroup ? 'stable' : version}`;

  const [isReady, setIsReady] = useState(false);

  const state = useReadmeApiInfinite<CustomBlockMgmtAPIDataInfinite>((pageIndex, previousPageData) => {
    if (previousPageData?.paging?.next === null) {
      // Reached the end of the list
      return null;
    }

    const search = filter.search;
    const sort = filter.sort === 'updated_at' ? filter.sort : `name&${filter.sort}=name`;

    return `${apiPath}/custom_blocks?type=${type}&page=${
      pageIndex + 1
    }&per_page=${PER_PAGE}&inherit=none&name=${search}&sort=${sort}`;
  });
  const { error, size, setSize, isLoading } = state;

  // Control loading spinner
  useEffect(() => {
    if (isReady || isLoading) return;
    setIsReady(true);
  }, [isLoading, isReady]);

  const firstItemTag = state?.data?.[0].data?.[0]?.tag;

  const errorStateProps = useMemo(() => {
    if (error?.status === 403) {
      return {
        message: `Contact an admin of ${name} to change your permissions.`,
        title: "You can't access Reusable Content",
        error,
      };
    }
    return { error };
  }, [name, error]);

  return (
    <>
      {error ? (
        <ErrorState {...errorStateProps} />
      ) : !isReady ? (
        <div className={bem('_loading')}>
          <Spinner size="lg" />
        </div>
      ) : (
        <Switch>
          <Route path={`${path}/:tag`}>
            <Content
              apiPath={apiPath}
              onLoadNextPage={() => {
                setSize(size + 1);
              }}
              state={state}
            />
          </Route>
          <Redirect to={`${path}/${firstItemTag || 'new'}`} />
        </Switch>
      )}
    </>
  );
}

interface CustomBlocktManagementProps {
  type?: 'component' | 'content';
}

export default function CustomBlocktManagement({ type = 'content' }: CustomBlocktManagementProps) {
  const bem = useClassy(classes, 'CustomBlockManagement');
  const {
    project: { plan, planOverride },
  } = useContext(ProjectContext) as ProjectContextValue;

  const isTrialActive = useIsTrialActive();

  /**
   * @todo Update logic if/when Custom Component permissions diverge from Reusable
           Content; until then, this code uses the same reusableContent permission.
   */
  const hasPermissions = usePlanPermissions(planOverride || plan, 'reusableContent');
  const isEnabled = isTrialActive || hasPermissions;

  const headerProps = useMemo(
    () =>
      type === 'content'
        ? { newIcon: 'recycle', title: 'Reusable Content' }
        : {
            newIcon: 'box',
            title: 'Components',
          },
    [type],
  );

  return (
    <CustomBlockMgmtContextProvider type={type}>
      <div className={bem('&', !isEnabled && '_disabled')}>
        {!isEnabled && <Cover />}
        <DashHeader {...headerProps} size="lg" />
        <ContentContainer className={bem('-container')} size="lg">
          <Box className={bem('-main')} kind="card">
            <Layout />
          </Box>
        </ContentContainer>
      </div>
    </CustomBlockMgmtContextProvider>
  );
}
