import React, { useContext, useEffect, useState } from 'react';

import { ProjectContext, type ProjectContextValue } from '@core/context';

import GitConnectionChooseRepo from '@routes/SuperHub/Settings/Form/Project/GitConnection/ChooseRepo';
import GitConnectionSetup from '@routes/SuperHub/Settings/Form/Project/GitConnection/Setup';

import { Page, PageContent } from '../shared';

function GitConnection() {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const [content, setContent] = useState<React.ReactNode | null>(null);

  const provider = project.git.sync.connectedRepository?.provider || 'github';
  const connections = project.git.sync[provider];

  useEffect(() => {
    if (connections && connections.length && provider) {
      setContent(<GitConnectionChooseRepo />);
      return;
    }

    setContent(<GitConnectionSetup />);
  }, [project, connections, provider]);

  return (
    <Page>
      <PageContent>{content}</PageContent>
    </Page>
  );
}

export default GitConnection;
