import React from 'react';
import { matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import BasePanel from '@routes/SuperHub/Layout/BasePanel';
import { SuperHubHashRoutePaths, type SuperHubHashRouteParams } from '@routes/SuperHub/types';

import ProjectSettingsForm from '../Form/Project';
import CustomLogin from '../Form/Project/CustomLogin';
import GitConnection from '../Form/Project/GitConnection';
import Glossary from '../Form/Project/Glossary';
import HealthCheck from '../Form/Project/HealthCheck';
import Integrations from '../Form/Project/Integrations';
import ProjectSettings from '../Form/Project/ProjectSettings';
import ReusableContent from '../Form/Project/ReusableContent';
import { SidebarNav, SidebarNavLink, SidebarNavSection } from '../Form/Project/shared';

import styles from './index.module.scss';

/**
 * Displays navigation and routes for project configuration settings.
 */
function Configure() {
  const bem = useClassy(styles, 'Configure');

  const { pathname } = useLocation();
  const isSuperHubDevelopment = useSuperHubStore(s => s.isSuperHubDevelopment);
  const match = matchPath<SuperHubHashRouteParams>(pathname, Object.values(SuperHubHashRoutePaths));
  const action = match?.params.action;

  return (
    <BasePanel label="Project settings controls">
      <div className={bem('-layout')} data-testid="configure-panel">
        <SidebarNav className={bem('-sidebar')}>
          {action === 'configure' && (
            <>
              <SidebarNavSection label="Project">
                <SidebarNavLink icon="book-open" label="Project Settings" to="/configure/project-settings" />
                <SidebarNavLink href="/dash?to=errors" icon="alert-triangle" label="Error Pages" target="_blank" />
                <SidebarNavLink icon="radio" label="Health Check" to="/configure/health-check" />
                <SidebarNavLink
                  href="/dash?to=personalized-docs"
                  icon="sparkles"
                  label="Personalized Docs"
                  target="_blank"
                />
              </SidebarNavSection>
              <SidebarNavSection label="Access">
                <SidebarNavLink href="/dash?to=api-key" icon="key" label="API Keys" target="_blank" />
                <SidebarNavLink href="/dash?to=password" icon="unlock" label="Internal Documentation" target="_blank" />
                <SidebarNavLink href="/dash?to=users" icon="teammates" label="Manage Team" target="_blank" />
                <SidebarNavLink icon="unlock" label="Custom Login" to="/configure/custom-login" />
                <SidebarNavLink href="/dash?to=saml" icon="lock" label="SAML Authentication" target="_blank" />
              </SidebarNavSection>
              <SidebarNavSection label="Admin">
                <SidebarNavLink href="/dash?to=plans" icon="credit-card" label="Manage Plan" target="_blank" />
                <SidebarNavLink href="/dash?to=domains" icon="globe" label="Custom Domain" target="_blank" />
                <SidebarNavLink icon="minimize-2" label="Integrations" to="/configure/integrations" />
                <SidebarNavLink href="/dash?to=labs" icon="labs" label="Labs" target="_blank" />
                {!!isSuperHubDevelopment && (
                  <SidebarNavLink icon="git-branch" label="Git Connection" to="/configure/git-connection" />
                )}
              </SidebarNavSection>
            </>
          )}
          {action === 'content' && (
            <SidebarNavSection>
              <SidebarNavLink icon="recycle" label="Reusable Content" to="/content/reusable-content" />
              <SidebarNavLink icon="bookmark" label="Glossary" to="/content/glossary" />
            </SidebarNavSection>
          )}
        </SidebarNav>

        {/* Routes to render sub-forms, grouped by sections in alphabetical order for readability */}
        <Switch>
          {/* Configure routes */}
          <Route path="/:action(configure)/:section(custom-login)">
            <ProjectSettingsForm>
              <CustomLogin />
            </ProjectSettingsForm>
          </Route>
          <Route path="/configure/git-connection">
            <ProjectSettingsForm>
              <GitConnection />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(configure)/:section(health-check)">
            <ProjectSettingsForm>
              <HealthCheck />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(configure)/:section(integrations)">
            <ProjectSettingsForm>
              <Integrations />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(configure)/:section(project-settings)">
            <ProjectSettingsForm>
              <ProjectSettings />
            </ProjectSettingsForm>
          </Route>
          <Route exact path="/:action(configure)">
            <Redirect to="/configure/project-settings" />
          </Route>

          {/* Content routes */}
          <Route path="/:action(content)/:section(reusable-content)">
            <ReusableContent />
          </Route>
          <Route path="/:action(content)/:section(glossary)">
            <ProjectSettingsForm>
              <Glossary />
            </ProjectSettingsForm>
          </Route>
          <Route exact path="/:action(content)">
            <Redirect to="/content/reusable-content" />
          </Route>
        </Switch>
      </div>
    </BasePanel>
  );
}

export default Configure;
