import React, { useCallback } from 'react';

import useClassy from '@core/hooks/useClassy';
import useEnvInfo from '@core/hooks/useEnvInfo';
import { useSuperHubStore } from '@core/store';
import { isGuidesPage, isReferencePage } from '@core/store/SuperHub/Document/util';

import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Segment, { SegmentItem } from '@ui/Segment';

import AppearanceNavigationMenu from '../../Settings/Appearance/NavigationMenu';
import BaseNav from '../BaseNav';
import BasePanel from '../BasePanel';
import { useSuperHubContext } from '../Context';
import Transition from '../Transition';

import ProjectNavigationBreadcrumb from './Breadcrumb';
import ProjectNavigationButton from './Button';
import classes from './index.module.scss';
import ProjectMenu from './ProjectMenu';

interface ProjectNavigationProps {
  className?: string;
}

/**
 * Displays the project controls for the SuperHub layout,
 * which show at the root hash route.
 */
export default function ProjectNavigation({ className }: ProjectNavigationProps) {
  const bem = useClassy(classes, 'ProjectNavigation');
  const { isClient } = useEnvInfo();
  const [layout, slug, doc, isEditing, routeSection, firstPage] = useSuperHubStore(s => [
    s.layout,
    s.slug || '',
    s.document.data,
    s.isEditing,
    s.routeSection,
    s.sidebar.findSidebarFirstPage,
  ]);
  const { browserRouterHistory } = useSuperHubContext();

  const isEditableSection = ['changelog', 'docs', 'page', 'reference', 'recipes'].includes(routeSection ?? '');
  const isSettingsLayout = ['settings', 'settings-preview'].includes(layout);

  const handleEdit = useCallback(() => {
    // If the user is navigated to an editable page, we take them to that page in the editor.
    // Otherwise, we take them to the docs section.
    const entrySection = isEditableSection ? routeSection : 'docs';
    browserRouterHistory.push(`/update/${entrySection}/${slug}`);
  }, [browserRouterHistory, isEditableSection, routeSection, slug]);

  const handlePreview = useCallback(() => {
    const isGuideReferencePage = isGuidesPage(doc) || isReferencePage(doc);
    const redirectSlug = (isGuideReferencePage && doc.type === 'link') || !slug ? firstPage?.()?.slug : slug;
    const redirectPath = [routeSection, redirectSlug].filter(Boolean).join('/');

    browserRouterHistory.push(`/${redirectPath}`);
  }, [browserRouterHistory, doc, firstPage, routeSection, slug]);

  return (
    <Transition
      className={className}
      enter={[
        { transform: 'translateY(calc(var(--SuperHub-top-nav-height) * -1))', opacity: 0 },
        { transform: 'translateY(0)', opacity: 1 },
      ]}
      in={layout !== 'aside-standalone'}
    >
      <BasePanel label={'Project controls'}>
        <BaseNav className={bem('-container', isSettingsLayout && '-container_two-column')}>
          <div className={bem('-left')}>
            <ProjectMenu />
            <ProjectNavigationBreadcrumb />
          </div>

          {!isSettingsLayout && (
            <Segment circular className={bem('-mode-toggle')} ghost selectedIndex={isEditing ? 1 : 0} size="xs">
              <SegmentItem onClick={handlePreview}>
                <Icon name="eye" />
                View
              </SegmentItem>
              <SegmentItem onClick={handleEdit}>
                <Icon name="edit" size="sm" />
                Edit
              </SegmentItem>
            </Segment>
          )}

          <div className={bem('-right')}>
            {isSettingsLayout ? (
              <ProjectNavigationButton
                aria-label="Close Settings"
                onClick={() => {
                  window.location.hash = '/';
                }}
              >
                <Icon name="x" />
              </ProjectNavigationButton>
            ) : (
              <>
                <ProjectNavigationButton
                  aria-label="Content"
                  onClick={() => {
                    window.location.hash = '/content/reusable-content';
                  }}
                  showTooltip
                >
                  <Icon name="text" />
                </ProjectNavigationButton>

                <Dropdown appendTo={isClient ? document.body : 'parent'} clickInToClose justify="end">
                  <ProjectNavigationButton aria-label="Appearance" dropdown showTooltip>
                    <Icon name="brush" />
                  </ProjectNavigationButton>
                  <AppearanceNavigationMenu />
                </Dropdown>
              </>
            )}
          </div>
        </BaseNav>
      </BasePanel>
    </Transition>
  );
}
