import React from 'react';

import useClassy from '@core/hooks/useClassy';

import styles from './index.module.scss';

interface PageContentProps {
  children?: React.ReactNode;
  fullWidth?: boolean;
  isCondensed?: boolean;
}

function PageContent({ children, fullWidth = false, isCondensed = false }: PageContentProps) {
  const bem = useClassy(styles, 'PageContent');
  return <section className={bem('&', fullWidth && '_fullWidth', isCondensed && '_condensed')}>{children}</section>;
}

export default PageContent;
