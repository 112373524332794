import type { NavigationRepresentationType } from '@readme/api/src/mappings/project/types';

import React, { useRef } from 'react';
import { useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import useEventListener from '@core/hooks/useEventListener';
import useUniqueId from '@core/hooks/useUniqueId';

import { useProjectSettingsFormContext } from '@routes/SuperHub/Settings/Form/Project';

import Button from '@ui/Button';
import Collapsible from '@ui/Collapsible';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import InputGroup from '@ui/InputGroup';
import { RHFGroup } from '@ui/RHF';
import Select from '@ui/Select';

import CustomPagePicker from '../CustomPagePicker';
import NavItem from '../NavItem';

import classes from './index.module.scss';

const linkLabels: Record<NavigationRepresentationType['type'], string> = {
  home: 'Home',
  guides: 'Guides',
  discussions: 'Discussions',
  changelog: 'Changelog',
  link_url: 'Link URL',
  custom_page: 'Custom Page',
  user_controls: 'User Controls',
  reference: 'API Reference',
  recipes: 'Recipes',
  search_box: 'Search Box',
};

interface NavListFieldGroupProps {
  /**
   * Whether or not the item can be moved down (not the last item).
   */
  canMoveDown: boolean;
  /**
   * Whether or not the item can be moved down (not the first item).
   */
  canMoveUp: boolean;
  /**
   * Whether or not the container is open (with inputs shown) or collapsed.
   */
  isOpen: boolean;
  /**
   * List of options used in the `type` select field.
   */
  itemTypeOptions?: NavigationRepresentationType['type'][];
  /**
   * Name of the indexed field array item to register with the form.
   * @example 'appearance.navigation.left[0]'
   */
  name: 'appearance.navigation.left.0' | 'appearance.navigation.right.0' | 'appearance.navigation.sub_nav.0';
  /**
   * Callback to move the item down in the list.
   */
  onMoveDown: () => void;
  /**
   * Callback to move the item up in the list.
   */
  onMoveUp: () => void;
  /**
   * Callback to open the container (showing the inputs).
   */
  onOpen: () => void;
  /**
   * Callback to remove the item from the list.
   */
  onRemove: () => void;
}

/**
 * Renders a group of inputs for configuring a single navigation respresentation item
 * within a `NavList` component.
 */
function NavListFieldGroup({
  name,
  onRemove,
  onMoveUp,
  onMoveDown,
  onOpen,
  canMoveUp,
  canMoveDown,
  isOpen,
  itemTypeOptions,
}: NavListFieldGroupProps) {
  const elementRef = useRef<HTMLLIElement | null>(null);
  const uid = useUniqueId('NavListFieldGroup');
  const bem = useClassy(classes, 'NavListFieldGroup');

  const { control } = useProjectSettingsFormContext();
  const { type, title } = useWatch({ control, name });

  // If there are any errors present on child fields, the parent NavList will force isOpen to true
  // without assigning this FieldGroup to it's `openIndex` state.
  // If the user focuses a field in that state, we want to call `onOpen` so the parent NavList assigns
  // this FieldGroup to it's `openIndex`.
  useEventListener(
    'focusin',
    () => {
      onOpen();
    },
    { target: elementRef.current },
  );

  return (
    <NavItem ref={elementRef} className={bem('&')} open={isOpen}>
      {!isOpen && !!type && (
        <button className={bem('-toggle')} onClick={onOpen}>
          <span className={bem('-toggle-label')}>
            {(['custom_page', 'link_url'].includes(type) && title) || linkLabels[type]}
          </span>
        </button>
      )}
      <Collapsible className={bem(isOpen && '-collapsible_open')} opened={isOpen}>
        <Flex
          align="stretch"
          gap="sm"
          justify="start"
          layout="col"
          onClick={(ev: React.MouseEvent) => ev.stopPropagation()}
        >
          <RHFGroup control={control} id={uid('link-type')} name={`${name}.type`}>
            {({ field }) => (
              <InputGroup columnLayout="auto 1fr" size="sm">
                <div className={bem('-input-group-label')}>
                  <label htmlFor={uid('link-type')}>Type</label>
                </div>
                <Select
                  {...field}
                  options={itemTypeOptions?.map(option => ({ label: linkLabels[option], value: option }))}
                  size="sm"
                  value={typeof field.value === 'string' ? field.value : ''}
                />
              </InputGroup>
            )}
          </RHFGroup>

          {['link_url', 'custom_page'].includes(type || '') && (
            <RHFGroup control={control} id={uid('title')} name={`${name}.title`} required>
              {({ field }) => (
                <InputGroup columnLayout="auto 1fr" size="sm">
                  <div className={bem('-input-group-label')}>
                    <label htmlFor={uid('title')}>Title</label>
                  </div>
                  <Input
                    {...field}
                    placeholder="Title"
                    size="sm"
                    value={typeof field.value === 'string' ? field.value : ''}
                  />
                </InputGroup>
              )}
            </RHFGroup>
          )}

          {type === 'link_url' && (
            <RHFGroup control={control} id={uid('url')} isUrl name={`${name}.url`} required>
              {({ field }) => (
                <InputGroup columnLayout="auto 1fr" size="sm">
                  <div className={bem('-input-group-label')}>
                    <label htmlFor={uid('url')}>URL</label>
                  </div>
                  <Input
                    {...field}
                    placeholder="URL"
                    size="sm"
                    value={typeof field.value === 'string' ? field.value : ''}
                  />
                </InputGroup>
              )}
            </RHFGroup>
          )}

          {type === 'custom_page' && (
            <RHFGroup control={control} id={uid('custom-page')} name={`${name}.custom_page`} required>
              {({ field }) => (
                <InputGroup columnLayout="auto 1fr" size="sm">
                  <div className={bem('-input-group-label')}>
                    <label htmlFor={uid('custom-page')}>Page</label>
                  </div>
                  <CustomPagePicker
                    id={field.id}
                    name={`${name}.custom_page`}
                    onChange={field.onChange}
                    value={typeof field.value === 'string' ? field.value : ''}
                  />
                </InputGroup>
              )}
            </RHFGroup>
          )}
        </Flex>
      </Collapsible>
      <Flex align="center" justify="end">
        <Flex className={bem('-controls')} gap="xs">
          {!!isOpen && (
            <Button aria-label="Remove" ghost kind="destructive" onClick={onRemove} size="xs">
              <Icon name="trash" />
            </Button>
          )}
          <Button
            aria-label="Move Up"
            disabled={!canMoveUp}
            ghost
            kind="secondary"
            onClick={ev => {
              ev.stopPropagation();
              onMoveUp();
            }}
            size="xs"
          >
            <Icon name="arrow-up" />
          </Button>
          <Button
            aria-label="Move Down"
            disabled={!canMoveDown}
            ghost
            kind="secondary"
            onClick={ev => {
              ev.stopPropagation();
              onMoveDown();
            }}
            size="xs"
          >
            <Icon name="arrow-down" />
          </Button>
        </Flex>
      </Flex>
    </NavItem>
  );
}

export default NavListFieldGroup;
