const VALID_HEX_PATTERN = [3, 4, 6, 8].map(digitCount => `#[0-9a-fA-F]{${digitCount}}`).join('|');
export const HEX_COLOR = new RegExp(`^(?:${VALID_HEX_PATTERN})$`, 'i');

// https://stackoverflow.com/a/20988824
// this is a pattern that we reuse in other queries so we can't use the ^ and $ anchors
export const MONGO_OBJECTID_REGEX = /[a-f\d]{24}/i;

// this ensures no characters but the mongo object id are included
export const STRICT_MONGO_OBJECTID_REGEX = new RegExp(`^${MONGO_OBJECTID_REGEX.source}$`, 'i');

export const SLUG_REGEX = /[a-z0-9-_]+/i;

export const CATEGORY_TITLE_REGEX = /(.*)/;
export const RESOURCE_ID_OR_SLUG = new RegExp(`${MONGO_OBJECTID_REGEX.source}|${SLUG_REGEX.source}`, 'i');

/**
 * @example `/changelogs/2023-06-15-update`
 * @example `/changelogs/e175cac316a79afdd0ad3afb`
 */
export const CHANGELOG_URI_REGEX = new RegExp(`/changelogs/(${MONGO_OBJECTID_REGEX.source}|${SLUG_REGEX.source})`, 'i');

/**
 * @example 1
 * @example 1.1
 * @example 1.1.1
 * @example 4-debug.test
 */
export const SEMVER_REGEX = /([0-9]+)(?:\.([0-9]+))?(?:\.([0-9]+))?(-.*)?/;

/**
 * @example stable
 * @example 1.1
 * @example 1.1.1
 * @example 4-debug.test
 */
export const VERSION_REGEX = new RegExp(`stable|${SEMVER_REGEX.source}`, 'i');

/**
 * @example `/versions/stable/custom_blocks/e175cac316a79afdd0ad3afb`
 * @example `/versions/45cbc4a0e4123f6920000000/custom_blocks/e175cac316a79afdd0ad3afb`
 */
export const CUSTOM_BLOCK_URI_REGEX = new RegExp(
  `/versions/(stable|${MONGO_OBJECTID_REGEX.source})/custom_blocks/(${MONGO_OBJECTID_REGEX.source})`,
  'i',
);

/**
 * @example `/versions/stable/custom_blocks/page-slug`
 * @example `/versions/1.0.0/custom_blocks/page-slug`
 */
export const CUSTOM_BLOCK_GIT_URI_REGEX = new RegExp(`/versions/(${VERSION_REGEX.source})/custom_blocks/(\\w+)`, 'i');

/**
 * @example `/custom_pages/our-custom-page`
 * @example `/custom_pages/e175cac316a79afdd0ad3afb`
 */
export const CUSTOM_PAGE_URI_REGEX = new RegExp(
  `/versions/(${VERSION_REGEX.source})/custom_pages/(${RESOURCE_ID_OR_SLUG.source})`,
  'i',
);

/**
 * @example `/versions/stable/guides/e175cac316a79afdd0ad3afb`
 * @example `/versions/1.1/guides/e175cac316a79afdd0ad3afb`
 * @example `/versions/stable/reference/e175cac316a79afdd0ad3afb`
 * @example `/versions/1.1/reference/e175cac316a79afdd0ad3afb`
 *
 * @deprecated Use `PAGE_URI_REGEX` instead as you adopt Git throughout our Page APIs.
 */
export const BASE_PAGE_URI_REGEX = new RegExp(
  `/versions/${VERSION_REGEX.source}/(reference|guides)/${MONGO_OBJECTID_REGEX.source}`,
  'i',
);

/**
 * @example `/versions/stable/guides/getting-started`
 * @example `/versions/1.1/guides/getting-started`
 * @example `/versions/stable/reference/create-pet`
 * @example `/versions/1.1/reference/create-pet`
 */
export const PAGE_URI_REGEX = new RegExp(
  `/versions/(${VERSION_REGEX.source})/(reference|guides)/(${SLUG_REGEX.source})`,
  'i',
);

/**
 * @example `/versions/stable/guides/e175cac316a79afdd0ad3afb`
 * @example `/versions/1.1/guides/e175cac316a79afdd0ad3afb`
 */
export const GUIDE_PAGE_URI_REGEX = new RegExp(
  `/versions/(${VERSION_REGEX.source})/guides/(${MONGO_OBJECTID_REGEX.source})`,
  'i',
);

/**
 * @example `/versions/stable/categories/guides/documentation`
 * @example `/versions/1.1/categories/guides/documentation`
 */
export const CATEGORY_URI_REGEX = new RegExp(
  `/versions/(${VERSION_REGEX.source})/categories/(guides|reference)/(${CATEGORY_TITLE_REGEX.source})`,
  'i',
);

/**
 * @example `/images/e175cac316a79afdd0ad3afb`
 */
export const IMAGE_URI_REGEX = new RegExp(`/images/(${MONGO_OBJECTID_REGEX.source})`, 'i');

/**
 * @example `/versions/stable/recipes/make-an-api-call`
 * @example `/versions/1.1/recipes/make-an-api-call`
 */
export const RECIPE_URI_REGEX = new RegExp(`/versions/(${VERSION_REGEX.source})/recipes/(${SLUG_REGEX.source})`, 'i');

/**
 * @example petstore
 * @example devilbert-clone
 */
export const PROJECT_SUBDOMAIN_REGEX = /[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*/;

/**
 * @example `/projects/me`
 * @example `/projects/petstore`
 * @example `/projects/devilbert-clone`
 */
export const PROJECT_URI_REGEX = new RegExp(`/projects/(me|${PROJECT_SUBDOMAIN_REGEX.source})`, 'i');

/**
 * Git uses SHA1 to create its hashes.
 *
 * @example `bb9d188153dae4cb8e5a62e8e1011585343083c3`
 * @example `bb9d1`
 */
export const GIT_HASH_REGEX = /[0-9a-f]{5,40}/;

/**
 * @example `petstore.json`
 * @example `petstore.yaml`
 * @example `petstore.yml`
 */
export const API_FILENAME_REGEX = new RegExp(`(${SLUG_REGEX.source}.(json|yaml|yml))`, 'i');

/**
 * @example `/versions/stable/apis/petstore.json`
 * @example `/versions/1.1/apis/petstore.json`
 * @example `/versions/stable/apis/petstore.yaml`
 * @example `/versions/1.1/apis/petstore.yaml`
 */
export const API_URI_REGEX = new RegExp(`/versions/(${VERSION_REGEX.source})/apis/(${API_FILENAME_REGEX.source})`, 'i');
