import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Icon from '@ui/Icon';

import { useProjectSettingsFormContext } from '..';
import { Page, PageContent } from '../shared';

import styles from './index.module.scss';
import GlossaryTerm from './Term';
import GlossaryTermInput from './TermInput';
import GlossaryTooltip from './Tooltip';

const mouseOverTerm = {
  definition: 'When a user moves their mouse pointer over a target element on screen, a mouse event is triggered.',
  term: 'mouseover',
};

export default function Glossary() {
  const bem = useClassy(styles, 'Glossary');
  const {
    control,
    formState: { isDirty, isSubmitSuccessful },
    reset,
  } = useProjectSettingsFormContext();
  const [creatingAt, setCreatingAt] = useState<number>();
  const [editingAt, setEditingAt] = useState<number>();

  const {
    fields: glossaryTerms,
    prepend,
    remove,
  } = useFieldArray({
    control,
    name: 'glossary',
  });

  const buttonRef = useRef<HTMLButtonElement>(null);
  const submitForm = () => buttonRef.current?.click();

  // Clears the "edit" state after saving a new or existing term.
  useEffect(() => {
    if (isSubmitSuccessful) {
      setCreatingAt(undefined);
      setEditingAt(undefined);
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  // Handles submitting the form "after" a term has been deleted. With RHF field
  // arrays, actions cannot be stacked one after another. When removing a term,
  // we have to use an effect to submit the form after the removal is rendered.
  // https://www.react-hook-form.com/api/usefieldarray/
  useEffect(() => {
    if (creatingAt === undefined && editingAt === undefined && isDirty) {
      submitForm();
    }
  }, [creatingAt, editingAt, isDirty]);

  const isFormActive = Number.isInteger(creatingAt ?? editingAt);

  return (
    <Page renderActionSlot={false}>
      <PageContent>
        <header className={bem('-header')}>
          <div>
            <h4 className={bem('-heading')}>Glossary</h4>
            <p className={bem('-sub-heading')}>
              Your glossary terms are highlighted words or phrases in your docs. Definitions pop-up when users{' '}
              <GlossaryTooltip term={mouseOverTerm}>{mouseOverTerm.term}</GlossaryTooltip>.
            </p>
          </div>

          <Button
            className={bem('-header-cta')}
            disabled={isFormActive}
            kind={isFormActive ? 'secondary' : 'primary'}
            onClick={() => {
              prepend({ term: '', definition: '' });
              setCreatingAt(0);
            }}
            size="sm"
          >
            <Icon name="plus" />
            Add Term
          </Button>
        </header>

        <ul className={bem('-list')}>
          {glossaryTerms?.map((glossaryTerm, index) => {
            const isEditing = index === creatingAt || index === editingAt;
            const isInActive = !isEditing && (creatingAt !== undefined || editingAt !== undefined);
            return (
              <li
                key={glossaryTerm.id}
                className={bem('-list-item', isEditing && '-list-item_editing', isInActive && '-list-item_inactive')}
              >
                {isEditing ? (
                  <GlossaryTermInput
                    index={index}
                    isCreating={index === creatingAt}
                    onCancel={() => {
                      setCreatingAt(undefined);
                      setEditingAt(undefined);
                      reset();
                    }}
                    onDelete={() => {
                      setCreatingAt(undefined);
                      setEditingAt(undefined);
                      remove(index);
                    }}
                  />
                ) : (
                  <GlossaryTerm
                    disabled={!!isFormActive && !isEditing}
                    onEdit={() => setEditingAt(index)}
                    term={glossaryTerm}
                  />
                )}
              </li>
            );
          })}
        </ul>

        {/* Hidden button so we can programatically submit the form */}
        <button ref={buttonRef} hidden type="submit" />
      </PageContent>
    </Page>
  );
}
